@import "./variables.scss";
@import "./menu.scss";
@import "./other_pages.scss";

// Navbar
.navbar {
  top: 0;
  left: 0;
  position: sticky;
  z-index: 10;
  padding: 0 4.1vw;
  background: #fff;
  height: 12vh;
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
  transition: all 0.3s ease-in-out;
  .navbar__logo {
    width: 170px;
    img {
      width: 100%;
    }
  }
  .navbar__ul {
    margin: 0;
    padding: 0;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    justify-content: end;
    li {
      padding: 0 2vw;
      line-height: 2rem;
      span {
        color: transparent;
        font-size: 0.7rem;
      }
      a {
        display: inline-block;
        align-items: unset;

        &:hover {
          color: $primary-color;
          transform: scale(1.1);
          transform-origin: center top;
          transition: all 0.5s;
          &::after {
            width: 100%;
            background: $primary-color;
          }
        }
        &:after {
          content: "";
          display: block;
          height: 2px;
          width: 0;
          background: transparent;
          transition: width 0.5s ease, background-color 0.5s ease;
        }
      }
    }
    .navbar__prodotti {
      position: relative;
      a {
        &:hover {
          transition: all 0.3s;
        }
      }
      .prodotti__ul {
        position: absolute;
        margin-top: 15px;
        transition: all 0.3s;
        top: end;
        left: 0;
        width: 180%;
        padding: 10% 0;
        padding-top: 30%;
        background-color: $transparent-white;
        visibility: hidden;
        opacity: 0;
        li {
          font-size: 0.8rem;
          font-weight: 600;
          line-height: 2rem;
          margin: 5px 0;
        }
      }
    }
  }
}

// Slider Home
.my-slider-progress {
  background: $medium-color;
  .my-slider-progress-bar {
    background: $primary-color;
    height: 0.5vh;
    transition: width 400ms ease;
    width: 0;
  }
}

.splide__track {
  height: 87.5vh;
  h1 {
    margin: 10vh 0;
    font-family: Yantramanav, sans-serif;
    color: white;
    font-size: 5rem;
    text-align: left;
    padding-left: 5vw;
    line-height: 5.5rem;
    transition: all 0.7s ease-in-out;
    transform: translateY(30px);
  }
  button {
    margin-left: 4vw;
    padding-left: 20px;
    transition: all 0.3s;
    transform: translateY(60px);
    margin-left: 5vw;
    position: relative;
  }
  img {
    width: 100%;
  }
  .splide__list {
    text-align: center;
    align-items: center;
    .slide--1 {
      height: 100%;
      z-index: -2;
      background: url(../img/deals.jpg)
        no-repeat center/cover;
    }
    .slide--2 {
      height: 100%;
      z-index: -2;
      background: url(../img/Dummy_imgs/consulenza.jpg) no-repeat center/cover;
    }
    .slide--3 {
      height: 100%;
      z-index: -2;
      background: url(../img/Dummy_imgs/news.jpg) no-repeat center/cover;
    }
  }
}

// Cards bar
#cards-bar {
  margin: 15vh 1vw;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 20px;
  padding: 20px;
  height: 20vh;
  justify-content: center;
  .card {
    position: relative;
    z-index: 1;
    display: flex;
    height: 100%;
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    color: $light-color;
    border-radius: 5px;
    align-items: center;
    justify-content: center;
    .overlay {
      opacity: 0.6;
      border-radius: 5px;
    }
    &:hover {
      scale: 1.1;
      transition: all 0.3s ease-in-out;
      cursor: pointer;
      .overlay {
        opacity: 0.3;
      }
    }
  }
  .card--1 {
    background: url(../img/Dummy_imgs/castra-infissi-pvc-sallustio-4-scaled.jpeg)
      no-repeat center/cover;
  }
  .card--2 {
    background: url(../img/Dummy_imgs/infissi-moderni-pvc.jpg) no-repeat
      center/cover;
  }
  .card--3 {
    background: url(../img/Dummy_imgs/schermature.jpg) no-repeat center/cover;
  }
  .card--4 {
    background: url(../img/Dummy_imgs/controtelai.jpg) no-repeat center/cover;
  }
  .card--5 {
    background: url(../img/Dummy_imgs/mosquito-19487_1920.jpg) no-repeat
      center/cover;
  }
  .card--6 {
    background: url(../img/Dummy_imgs/opere_in_vetro.jpg) no-repeat center/cover;
  }
}

// Realizzazioni home
#home-realizzazioni {
  margin: 0 10vw;
  button {
    margin: 5vh auto;
  }
  .presentazione {
    margin-bottom: 10vh;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    img {
      width: 80%;
      border-radius: 5px;
    }
    .presentazione__text {
      text-align: left;
      button {
        margin: 0;
        margin-top: 8vh;
      }
    }
  }
  .alcune__realizzazioni {
    padding: 10vh 0;
    img {
      width: 100%;
      border-radius: 5px;
    }
    .work {
      padding-top: 20vh;
      display: grid;
      grid-template-columns: 1fr 1fr;
      // grid-gap: 5vw;
      align-items: center;
      justify-content: center;
      .work__text {
        .work__title {
          font-size: 2.2rem;
          margin-bottom: 5vh;
        }
        .work__subtitle {
          color: $medium-color;
          margin: 0;
        }
        .work__description {
          margin-top: 0.5rem;
          line-height: 1.5;
          font-size: 1.1rem;
          font-weight: 500;
        }
      }
      .text__right {
        padding-left: 5vw;
      }
      .text__left {
        padding-right: 5vw;
      }
    }
  }
}

// Certificazioni Home
#home-certificazioni {
  text-align: center;
  padding: 20vh 10vw;
  .certificazioni__content {
    margin: auto;
    margin-top: 10vh;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-content: center;
    .certificazioni__loghi {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      img {
        margin: auto;
        width: 50%;
        padding: 20px;
      }
    }
  }
}

#richiesta-preventivo {
  padding: 5vh 10vw;
  padding-top: 0;
  p {
    padding: 10vh 0;
  }
  .form {
    text-align: left;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    // .item--4 {
    //   grid-column: 2;
    //   grid-row: 2 / span 2;
    // }
    button {
      margin: 10vh auto;
      text-align: center;
      grid-column: 1 / span 2;
    }
    .form__item {
      display: flex;
      flex-direction: column;
      width: 60%;
      margin: 0 auto;
      label {
        font-size: 1.3rem;
        font-weight: 500;
        padding: 20px 0;
      }
      input,
      textarea {
        width: 100%;
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 10px;
        font-family: Yantramanav, sans-serif;
        font-size: 1.1rem;
      }
    }
  }
}

#map {
  align-items: center;
  text-align: center;
  .googleMapsIframeContainer {
    .googleMapsIframe {
      width: 100%;
      height: 80vh;
    }
  }
}

#footer {
  padding: 0 0 0 13vw;
  padding-top: 50px;
  background-color: black;
  color: $light-color;
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  span {
    color: transparent;
  }
  a,
  iconify-icon {
    &:hover {
      color: $medium-color;
    }
  }
  .footer__links {
    padding: 10px 0;
    p {
      padding: 3px 0;
    }
  }
  .footer__contatti,
  .footer__link--utili,
  .footer__social {
    // margin: 0 auto;
    display: flex;
    flex-direction: column;
    .social__icon {
      padding: 20px 0;
    }
  }
  .footer__social {
    margin: auto 0;
    align-items: center;
    text-align: center;
    .iubenda-cs-preferences-link {
      padding: 20px;
      font-size: 0.6rem;
    }
  }

  .copyright {
    padding-top: 50px;
    font-size: 0.7rem;
  }
}

@import "./media_queries.scss";
